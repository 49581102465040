/////////////////////////////////////////////
//Dependencies
import React from 'react';

/////////////////////////////////////////////
//Imported Components
import NavbarDropdown from './NavbarDropdown';
import NavLinkX from './NavLink';

/////////////////////////////////////////////
//Component

/**
 * NavbarItemMode component
 * 
 * @param {Object} props                - The component props.
 * @param {Object} props.linkInfo       - The link information for the navbar item.
 * @param {string} props.linkInfo.mode  - The mode of the navbar item, either 'Dropdown' or 'Link'.
 * @returns {JSX.Element}               - The rendered NavbarItemMode component.
 */
export default function NavbarItemMode({ linkInfo }) {
  if (linkInfo.mode === 'Dropdown') {
    return <NavbarDropdown linkInfo={linkInfo} />;
  } else if (linkInfo.mode === 'Link') {
    return <NavLinkX linkInfo={linkInfo} />;
  }
};