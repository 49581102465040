
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

/////////////////////////////////////////////
//CSS
import "./css/Home.css";

/////////////////////////////////////////////
//Components
import Vitrine from "../Modules/vitrine/Vitrine";
import Presentation from "../Modules/presentation/Presentation";
import Map from "../Modules/map/Map";
import Chiffres from "../Modules/chiffre/Chiffre";
import Banner from "../Modules/banner/Banner";
import Domain from "../Modules/domain/Domain";
import Partners from "../Modules/partners/Partners";

import ReverseParseText from "../Modules/reverseParseText/ReverseParseText";

//////////////////////////////////////////////////////////////////////////////////////////

function Home() {
  document.title = "Plassys BESTEK";
  const { t } = useTranslation();
  const pageTitle = "Plassys BESTEK";
  const pageDescription = ReverseParseText(t("presentation.text"));

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <div className="container-home">
        <Vitrine />
        <Presentation />
        <Map />
        <Chiffres />
        <Banner
          imagelogo="qutek"
          imagebg="qutek.jpg"
          title={t('quantum.banner')}
          link="quantum"
          name={t('quantum.discover')}
        />
        <Domain />
        <Banner
          imagelogo="diamtek"
          imagebg="Diamtek-9.jpg"
          title={t('diamond.banner')}
          link="diamond"
          name={t('diamond.discover')}
        />
        <Partners />
      </div>
    </>
  );
}
export default Home;
