/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useLocation, NavLink } from "react-router-dom";

/////////////////////////////////////////////
//Component

/**
 * NavLinkX component
 * 
 * @param {Object} props                            - The component props.
 * @param {Object} props.linkInfo                   - The link information for the navbar item.
 * @param {string} props.linkInfo.link              - The URL to navigate to.
 * @param {string} props.linkInfo.name              - The display name for the link.
 * @param {boolean} [props.linkInfo.actual=false]   - Whether the link is the actual link.
 * @param {function} [props.onClick]                - The function to call when the link is clicked.
 * @returns {JSX.Element}                           - The rendered NavLinkX component.
 */
export default function NavLinkX({ linkInfo, onClick }) {
  const location = useLocation();
  const isActive = location.pathname === linkInfo.link;

  return (
    <div id={linkInfo.link}>
      <NavLink to={linkInfo.link} onClick={onClick} className={`full links-mobile ${isActive ? 'active' : ''}`} id={`${linkInfo.actual ? "actual" : ""}`}>
        <li className="nav-item">
          <span>{linkInfo.name}</span>
        </li>
      </NavLink>
    </div>
  );
};