/////////////////////////////////////////////
//Dependencies
import React from 'react'

/////////////////////////////////////////////
//CSS
import "./QutekHWItem.css"

/////////////////////////////////////////////
//Component

/**
 * QutekHWItem component
 * 
 * @param {Object} props - The component props.
 * @param {string} props.image - The name of the image with extension.
 * @param {string} props.title - The title to display.
 * @returns {JSX.Element} - The rendered QutekHWItem component.
 */
export default function QutekHWItem({ image, title }) {
    return (
        <>
            <div className="qutekHWItem-card" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/assets/${image})` }}>
                <h1 className='qutekHWItem-title'>{title}</h1>
            </div>
        </>
    )
};
