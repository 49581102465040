/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Helmet } from "react-helmet";

/////////////////////////////////////////////
//CSS


/////////////////////////////////////////////
//Components
import Sitemap from "../Modules/sitemap/Sitemap";

//////////////////////////////////////////////////////////////////////////////////////////

function SMap() {
  const pageTitle = "Plassys ▪ Site map";
  const pageDescription = "Plassys site map";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://plassys.com/sitemap" />
      </Helmet>

      <Sitemap />
    </>
  );
}
export default SMap;
