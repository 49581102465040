/////////////////////////////////////////////
//Dependencies
import { React } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import GppGoodIcon from '@mui/icons-material/GppGood';

/////////////////////////////////////////////
//CSS
import "./css/Software.css";

/////////////////////////////////////////////
//Components
import ParseText from '../Modules/parseText/ParseText';
import ReverseParseText from '../Modules/reverseParseText/ReverseParseText';

import BannerDomain from '../Modules/bannerdomain/BannerDomain';
import HistoryDomain from '../Modules/historyDomain/HistoryDomain';

//////////////////////////////////////////////////////////////////////////////////////////


export default function Software() {
    const { t } = useTranslation();
    const pageTitle = "Plassys ▪ " + t("navbar.SystemsDD.Software");
    const pageDescription = ReverseParseText(t("category.Software.text1"));

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href="https://plassys.com/categories/Software" />
            </Helmet>

            <div className='dalek-container-page'>

                {/*********************************************************************************** 
            Modify BannerDomain :

            <BannerDomain
              logo="Logo of the domain"                                (Put image in /public/img/logo)
              text="Text for the domain"                               (string)
              imagebg="Image background of the domain"                 (Put image in /public/img/assets)
            />

            ***********************************************************************************/}

                <BannerDomain
                    logo='../../../img/logo/dalek'
                    text={t('category.Software.catch')}
                    imagebg='../../../img/dalek/dalek-background.jpg'
                />

                <div className="dalek-body">

                    {/*********************************************************************************** 
                Modify HistoryDomain :
          
                <HistoryDomain
                name="Name of Domain"                                    (string)
                    text="Text for the domain"                               (string)
                    text2="Text for the domain"                              (string)
                    image="Image of the domain"                              (Put image in /public/img/assets)
                />

                Tips for text and text2:
                - Use '**' to bold a text: **bold**
                - Use '$' to italic a text: $italic$
                - Use '?' to underline a text: ?underline?
                You can combine them: **$?bold italic underline$?**
                ***********************************************************************************/}

                    <HistoryDomain
                        name='Dalek'
                        text={t('category.Software.text1')}
                        text2=""
                        image='../../../img/dalek/dalek-mockup.png'
                    />

                    <>
                        <h1 className='dalek-title-card'>{t('category.Software.titlecard')}</h1>
                        <div className="dalek-cards">
                            <div className="dalek-card">
                                <div className="comp-card-content">
                                    <div className='dalek-card-icon-container'>
                                        <BoltIcon className='dalek-card-icon' sx={{ fontSize: 40 }} />
                                    </div>
                                    <h1 className="dalek-card-title">{t("category.Software.card1.title")}</h1>
                                    <p className="comp-card-text">
                                        {t("category.Software.card1.text")}
                                    </p>
                                    <div className="spacer"></div>
                                </div>
                            </div>

                            <div className="dalek-card">
                                <div className="comp-card-content">
                                    <div className='dalek-card-icon-container'>
                                        <ThumbUpAltIcon className='dalek-card-icon' sx={{ fontSize: 40 }} />
                                    </div>
                                    <h1 className="dalek-card-title">{t("category.Software.card2.title")}</h1>
                                    <p className="comp-card-text">
                                        {t("category.Software.card2.text")}
                                    </p>
                                    <div className="spacer"></div>
                                </div>
                            </div>

                            <div className="dalek-card">
                                <div className="comp-card-content">
                                    <div className='dalek-card-icon-container'>
                                        <GppGoodIcon className='dalek-card-icon' sx={{ fontSize: 40 }} />
                                    </div>
                                    <h1 className="dalek-card-title">{t("category.Software.card3.title")}</h1>
                                    <p className="comp-card-text">
                                        {t("category.Software.card3.text")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>

                    <div className="dalek-features">
                        <h1 className='dalek-title-feature'>{t('category.Software.features.title')}</h1>

                        <div className='dalek-features-container'>
                            <div className='dalek-features-right'>
                                <div className='dalek-features-right-image'>
                                    <img src="../../img/dalek/dalek-interface.jpg" alt="" />
                                </div>
                                <div className='dalek-features-right-text'>
                                    <div className='dalek-features-text-list'>
                                        <ParseText text={t("category.Software.features.list1")} classN={""} />
                                    </div>
                                    <div className='dalek-features-text-list'>
                                        <ParseText text={t("category.Software.features.list2")} classN={""} />
                                    </div>
                                </div>
                            </div>
                            <div className='dalek-features-left'>
                                <div className='dalek-features-left-text'>
                                    <div className='dalek-features-text-list'>
                                        <ParseText text={t("category.Software.features.list3")} classN={""} />
                                    </div>
                                    <div className='dalek-features-text-list'>
                                        <ParseText text={t("category.Software.features.list4")} classN={""} />
                                    </div>
                                </div>
                                <div className='dalek-features-left-image'>
                                    <img src="../../img/dalek/dalek-processeditor.png" alt="Dalek Process Editor" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='dalek-annexe'>

                        <div className='dalek-annexe-contact'>

                            <ParseText text={t("annexes.contact-text")} classN={"dalek-annexe-contact-text"} />

                            <NavLink to="/contact" className="dalek-annexe-contact-link">
                                <button className="dalek-annexe-contact-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                                    </svg>
                                    <div className="dalek-annexe-contact-button-text">
                                        {t('annexes.contact-button')}
                                    </div>
                                </button>
                            </NavLink>

                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}
