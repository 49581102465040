import React from 'react'
import { Helmet } from 'react-helmet';

import "./css/ee.css";
import { Link } from 'react-router-dom';

export default function EE() {
    return (
        <>
            <Helmet>
                <title>{'<(^(oo)^)>'}</title>
                <meta name='robots' content='noindex, nofollow' />
            </Helmet>

            <noindex className="ee-content" id="cookies">
                <div className="ee-subtitle">PLASSYS TEAM</div>

                <div className="ee-text">

                    <br /><br />
                    <p className='ee-owo'>{'<(^(oo)^)>'}</p>
                    <br /><br />
                    <img src="/img/assets/c.png" alt="EE" style={{ width: '100px', height: '100px', verticalAlign: 'middle' }} />
                    <br /><br /><br />

                    <p>
                        Made in <img src="/img/icones/i-flag-france.png" alt="EE" style={{ width: '70px', height: '45px', verticalAlign: 'middle' }} />
                        <br />
                        Made with ❤️ <Link to="http://www.github.com/MatisAgr" target="_blank">MatisAgr</Link>
                    </p>

                </div>
            </noindex>

        </>
    )
}
