/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//Imported Components
import ParseText from "../parseText/ParseText";

/////////////////////////////////////////////

/**
 * MachineAdd component
 * 
 * @param {Object} props              - The component props.
 * @param {string} props.link         - The link for redirection.
 * @param {string} props.image        - The name of the image with extension.
 * @param {string} props.name         - The name of the machine.
 * @param {string} props.desc         - The description of the machine.
 * @param {Array<string>} props.tags  - The tags associated with the machine.
 * @param {string} props.cat          - The category of the machine.
 * @returns {JSX.Element}             - The rendered MachineAdd component.
 */
export default function MachineAdd({ link, image, name, desc, tags, cat }) {
  const { t } = useTranslation();
  return (
    <article>
      <div className="article-wrapper">
        <figure>
          <img src={`../img/machines/${image}`} alt={name} />
        </figure>
        <div className="article-body">
          <h2>{name}</h2>
          <hr></hr>
          <ParseText classN={null} text={desc} />
          <Link to={`/categories/${cat}/${link}`} className="read-more">
            {t("domain.more")} <span className="sr-only"></span>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            <div className="tags" style={{ display: "none" }} data-tags={tags} />
          </Link>
        </div>
      </div>
    </article>
  );
};

