/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//Imported Components
import ParseText from "../parseText/ParseText";

/////////////////////////////////////////////
//CSS
import "./Chiffre.css";

/////////////////////////////////////////////
//Component

/**
 * Chiffres component
 * 
 * @returns {JSX.Element} - The rendered Chiffres component.
 */
export default function Chiffre() {
    const { t } = useTranslation();

    return (
        <div className="chiffres">
            <div className="title-container">
                <img className="PLASSYS-logo" src="../img/logo/plassys-logo.png" alt="PLASSYS" draggable="false" />
            </div>
            <div className="grid-container">
                <img className="grid-item-img position-1" src="../img/icones/i-machi.png" alt="i-mechi" id="i-mechi" draggable="false" />
                <img className="grid-item-img position-2" src="../img/icones/i-collab.png" alt="i-collab" id="i-collab" draggable="false" />
                <img className="grid-item-img position-3" src="../img/icones/i-client.png" alt="i-client" id="i-client" draggable="false" />
                <img className="grid-item-img position-4" src="../img/icones/i-monde.png" alt="i-monde" id="i-monde" draggable="false" />

                <span className="grid-item-chiffre position-5">530</span>
                <span className="grid-item-chiffre position-6">35</span>
                <span className="grid-item-chiffre position-7">15</span>
                <span className="grid-item-chiffre position-8">80%</span>

                <span><ParseText text={t('chiffre.systems')} classN="grid-item-text position-9" /></span>
                <span><ParseText text={t('chiffre.years')} classN="grid-item-text position-10" /></span>
                <span><ParseText text={t('chiffre.vacsyspy')} classN="grid-item-text position-11" /></span>
                <span><ParseText text={t('chiffre.export')} classN="grid-item-text position-12" /></span>

            </div>
        </div>
    )
};