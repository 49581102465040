/////////////////////////////////////////////
//Dependencies
import React from "react";

///////////////////////////////////////////

/**
 * FooterTitle component
 * 
 * @param {Object} props        - The component props.
 * @param {string} props.title  - The title to display.
 * @returns {JSX.Element}       - The rendered FooterTitle component.
 */
export default function FooterTitle({ title }) {
    return (
        <div className="title-footer">
            <h2>{title}</h2>
        </div>
    );
};