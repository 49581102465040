/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Link } from "react-router-dom";

///////////////////////////////////////////

/**
 * FooterLink component
 * 
 * @param {Object} props            - The component props.
 * @param {string} props.link       - The URL to navigate to.
 * @param {string} props.name       - The display name of the link.
 * @param {function} props.onClick  - The function to call when the link is clicked.
 * @returns {JSX.Element}           - The rendered FooterLink component.
 */
export default function FooterLink({ link, name, onClick }) {
  return (
    <Link onClick={onClick} to={`${link}`}>
      <li>{name}</li>
    </Link>
  );
};