/////////////////////////////////////////////
//Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

/////////////////////////////////////////////
//Imported Components
import ProductAdd from "./ProductCatAdd";

/////////////////////////////////////////////
//Component

/**
 * ProductsCat component
 * 
 * @returns {JSX.Element} - The rendered ProductsCat component.
 */
export default function ProductsCat() {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const categories = t("category", { returnObjects: true });

  /**
 * Normalize a string by removing spaces and hyphens, and converting to lowercase.
 * 
 * @param {string} str - The string to normalize.
 * @returns {string} - The normalized string.
 */
  const normalizeString = (str) => str.replace(/\s|-/g, '').toLowerCase();

  /**
   * Clear the search term.
   */
  const clearSearch = () => {
    setSearchTerm('');
  };

  const filteredCategories = Object.entries(categories)
    .map(([key, categoryData]) => {
      const searchKeywords = searchTerm.toLowerCase().split(' ').map(normalizeString);

      const matches = (text) => {
        const normalizedText = normalizeString(text);
        return searchKeywords.every(keyword => normalizedText.includes(keyword));
      };

      // Flexible matching for tags: each keyword must match any tag
      const tagMatches = categoryData.tags && searchKeywords.every(keyword =>
        categoryData.tags.some(tag => normalizeString(tag).includes(keyword))
      ) ? 2 : 0;

      const nameMatches = categoryData.name && matches(categoryData.name) ? 3 : 0;
      const descMatches = categoryData.desc && matches(categoryData.desc) ? 1 : 0;

      const score = nameMatches + tagMatches + descMatches;

      return { key, categoryData, score };
    })
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score);

  return (
    <div className="products-container">
      <h1 className="title-productCat">{t('categoryPage.title')}</h1>

      {/* <div className="search-bar-container">
        <input
          type="text"
          className="search-bar-item"
          placeholder={t('categoryPage.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm ? (
          <ClearIcon
            className="search-icon"
            onClick={clearSearch}
          />
        ) : (
          <SearchIcon className="search-icon" />
        )}
      </div> */}


      {filteredCategories.length > 0 ? (
        <section className="articles cat">
          {filteredCategories.map(({ key, categoryData }, index) => (
            <ProductAdd
              key={key}
              name={categoryData.name}
              link={categoryData.link}
              tags={categoryData.tags}
              image={categoryData.image}
              desc={categoryData.catch}
            />
          ))}
        </section>
      ) : (
        <h2 className="noResults">{t('notfound.category')}</h2>
      )}
    </div>
  );
};
