/////////////////////////////////////////////
//Dépendances
import { useState, useEffect } from "react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n.jsx";

/////////////////////////////////////////////
//CSS
import "./fonts.css";
import "./base.css";

import "./Component/Modules/navbar/Navbar.css";
import "./Component/Modules/footer/Footer.css";


/////////////////////////////////////////////
//Components
import Navbar from "./Component/Modules/navbar/Navbar";
import Footer from "./Component/Modules/footer/Footer";
import ScrollToTop from "./ScrollToTop";
import CookiePopup from "./Component/Modules/cookiepopup/cookiepopup";

import Home from "./Component/Pages/Home";
import Presentation from "./Component/Pages/Company.jsx";

import Categories from "./Component/Pages/Categories";

import Quantum from "./Component/Pages/Quantum";
import Diamond from "./Component/Pages/Diamond";
import Contact from "./Component/Pages/Contact";
import Distributors from "./Component/Pages/Distributors";

import Software from "./Component/Pages/Software.jsx";
import GetMachines from "./Component/Pages/GetMachines";
import MachineDet from "./Component/Pages/MachineDet";

import SiteMap from "./Component/Pages/SMap";
import Gallery from "./Component/Pages/Gallery";
import Recruitment from "./Component/Pages/Recruitment";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy";

import EE from "./Component/Pages/EE";
import NotFound from "./Component/Pages/NotFound";
import Loading from "./Component/Modules/loading/Loading";

//////////////////////////////////////////////////////////////////////////////////////////
function App() {
  const [loading, setLoading] = useState(true); // (false for desactivate loading screen)

  // Check if the window has loaded
  useEffect(() => {
    const startTime = performance.now();

    const handleLoad = () => {
      const endTime = performance.now();
      const loadingTime = endTime - startTime;

      setLoading(false);
      // console.log(`Website loading time  : ${loadingTime} ms`);
      // console.log("Website status : " + document.readyState);
    };

    // If the document is already loaded, call the handler right away
    if (document.readyState === "complete" || document.readyState === "interactive") {
      handleLoad();
    } else {
      document.addEventListener('DOMContentLoaded', handleLoad);
    }

    return () => document.removeEventListener('DOMContentLoaded', handleLoad);
  }, []);



  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <I18nextProvider i18n={i18n}>
          <div className="App">
            <header className="App-header">
              <Navbar />
            </header>


            <div className="Content">
              <ScrollToTop />
              {/* <TempModal /> */}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/presentation" element={<Presentation />} />

                <Route path="/categories" element={<Categories />} />

                <Route path="/quantum" element={<Quantum />} />
                <Route path="/diamond" element={<Diamond />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/contact/distributors" element={<Distributors />} />

                <Route path="/categories/Software" element={<Software />} />
                <Route path="/categories/:categoryName" element={<GetMachines />} />
                <Route path="/categories/:categoryName/:machineName" element={<MachineDet />} />

                <Route path="/sitemap" element={<SiteMap />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/recruitment" element={<Recruitment />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />

                <Route path="/ee" element={<EE />} />
                <Route path="*" element={<NotFound />} />

              </Routes>
              <Footer />
            </div>
            <CookiePopup />
          </div>
        </I18nextProvider>
      )}
    </div>
  );
}
export default App;
