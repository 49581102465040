/////////////////////////////////////////////
//Dependencies
import React from "react";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//Imported Components
import ParseText from "../parseText/ParseText";

/////////////////////////////////////////////
//CSS
import "./Presentation.css";

/////////////////////////////////////////////
//Component

/**
 * Presentation component
 * 
 * @returns {JSX.Element} - The rendered Presentation component.
 */
export default function Presentation() {
  const { t } = useTranslation();

  return (
    <div className="pres-container" id="pres-container">
      <div className="pres-bg"></div>
      <div className="pres-title">
        <h2>{t("presentation.title")}</h2>
      </div>
      <div className="pres-text">
        <ParseText text={t("presentation.text")} />
      </div>
    </div>
  );
};