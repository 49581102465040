/////////////////////////////////////////////
//Dependencies
import React from 'react'

/////////////////////////////////////////////
//Imported Components
import ParseText from '../parseText/ParseText';

/////////////////////////////////////////////
//CSS
import "./HistoryDomain.css"

/**
 * HistoryDomain component
 * 
 * @param {Object} props        - The component props.
 * @param {string} props.name   - The name of the domain.
 * @param {string} props.text   - The main text content.
 * @param {string} props.text2  - The secondary text content.
 * @param {string} props.image  - The background image name.
 * @returns {JSX.Element}       - The rendered HistoryDomain component.
 */
export default function HistoryDomain({name, text, text2, image}) {
    return (
        <>
            <div className="history-container">
                <div className='history-top'>
                    <div className='history-top-left'>
                        <div className='history-top-left-titles'>
                            <h1 className='history-top-left-title'>{name}<span>TM</span></h1>
                            <h2 className='history-top-left-subtitle'>By PLASSYS</h2>
                        </div>
                        <ParseText text={text} classN='history-bottom-text'/>
                    </div>
                    <div className='history-top-right' style={{backgroundImage: `url(img/assets/${image})`}}>
                    </div>
                </div>
                <div className='history-bottom'>
                    <ParseText text={text2} classN='history-bottom-text'/>
                </div>
            </div>
        </>
    )
};