/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//CSS
import "./css/Recruitment.css";

/////////////////////////////////////////////
//Components
import ParseText from "../Modules/parseText/ParseText";
import ReverseParseText from "../Modules/reverseParseText/ReverseParseText";

//////////////////////////////////////////////////////////////////////////////////////////

function Recruitment() {
    const { t } = useTranslation();
    const pageTitle = "Plassys ▪ " + t("footer.link4-3");
    const pageDescription = ReverseParseText(t("recruitment.text"));

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href="https://plassys.com/recruitment" />
            </Helmet>

            <div className="recrut-container">

                <h1 className="recrut-title">{t("recruitment.title")}</h1>

                <div className="recrut-content">
                    <div className="recrut-subtitle">{t("recruitment.motiv")}</div>
                    <div className="recrut-text">
                        <ParseText text={t("recruitment.text")} classN={"recrut-text"} />
                        <Link to="mailto:recruitment@plassys.com" className="recrut-link">{t("navbar.Contact")}</Link>
                    </div>
                </div>

            </div>
        </>
    );
}
export default Recruitment;
