/////////////////////////////////////////////
//Dependencies
import React from 'react'

/////////////////////////////////////////////
//Component
import './ParseText.css'

/////////////////////////////////////////////

/**
 * ParseText component
 * 
 * @param {Object} props                            - The component props.
 * @param {string} props.text                       - The text to parse and render.
 * @param {string} [props.classN="text-neutral"]    - The CSS class to apply to the rendered text.
 * @returns {JSX.Element}                           - The rendered ParseText component.
 */
export default function parseText({ text, classN }) {

    /**
     * Parse the input text and replace custom markup with HTML.
     * 
     * @param {string} str - The input text to parse.
     * @returns {string} - The parsed HTML string.
     */
    const parseText = (str) => {
        let parsedText = str
            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')                                                             // Bold         (exemple: **Bold**)
            .replace(/\$(.*?)\$/g, '<i>$1</i>')                                                                 // Italic       (exemple: $Italic$)
            .replace(/__(.*?)__/g, '<u>$1</u>')                                                                 // Underline    (exemple: __Underline__)
            .replace(/\[\[(.*?)\]\]/g, '<a href="$1" target="_blank">$1</a>')                                   // Link         (exemple: [[Link]])
            .replace(/##(.*?)##(.*?)##/g, '<a href="$1" target="_blank">$2</a>')                                // HiddenLink   (exemple: ##Link##Link Text##)
            .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" style="width: 15vh; float: right;" />');   // Image        (exemple: ![alt text](url))


        // Handle custom list format
        parsedText = parsedText.replace(/(\|\|)(.*?)(\|\|)/g, (match, p1, p2) => {
            const items = p2.split('--').map(item => `<li>${item.trim()}</li>`).join('');                       // List (exemple: ||Item 1--Item 2--Item3||)
            return `<ul>${items}</ul>`;
        });

        // Adjust the replacement of "//" to "<br />", excluding when inside <a> tags
        parsedText = parsedText.replace(/\/\/(?![^<]*>|[^<>]*<\/a>)/g, '<br />');                               // LineBreak (exemple: //Line Break)

        return parsedText;
    }

    if (classN === undefined) {
        classN = "text-neutral";
    }

    return (
        <>
            <p className={classN} dangerouslySetInnerHTML={{ __html: parseText(text) }}></p>
        </>
    )
};