/////////////////////////////////////////////
//Dependencies
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

/////////////////////////////////////////////
//CSS
import "./Map.css";

/////////////////////////////////////////////
//Function

ChartJS.register(ArcElement, Tooltip, Legend);

/////////////////////////////////////////////
//Component

/**
 * Map component
 * 
 * @returns {JSX.Element} - The rendered Map component.
 */
export default function Map() {
  const { t } = useTranslation();
  const rootStyles = getComputedStyle(document.documentElement);
  const legendTextColor = rootStyles.getPropertyValue('--dark-text').trim();
  const [geoUrl, setGeoUrl] = useState("");
  const [activeContinent, setActiveContinent] = useState(null);
  const geographyRefs = useRef([]);
  const tooltipRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const [allData, setAllData] = useState(null);
  const [translatedName, setTranslatedName] = useState("Continent");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showHoverTooltip, setShowHoverTooltip] = useState(false);

  const continentNames = {
    "Asia": "map.continent.asia",
    "Europe": "map.continent.europe",
    "Africa": "map.continent.africa",
    "America": "map.continent.america",
    "Oceania": "map.continent.oceania",
  };

  useEffect(() => {
    if (activeContinent && allData && !chartData[activeContinent.name]) {
      const data = getChartData(activeContinent, allData);
      setChartData(prevData => ({ ...prevData, [activeContinent.name]: data }));
    }
  }, [activeContinent, allData]);

  useEffect(() => {
    const storedMap = localStorage.getItem('mapData');
    if (storedMap) {
      const data = JSON.parse(storedMap);
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      setGeoUrl(URL.createObjectURL(blob));
      geographyRefs.current = Array(data.features.length).fill(null).map((_, i) => geographyRefs.current[i] ?? React.createRef());
    } else {
      fetch('/doc/map/mapLowSimpleOL.json') //GeoJSON file
        .then((response) => response.json())
        .then((data) => {
          const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
          setGeoUrl(URL.createObjectURL(blob));
          geographyRefs.current = Array(data.features.length).fill(null).map((_, i) => geographyRefs.current[i] ?? React.createRef());
          localStorage.setItem('mapDataJson', JSON.stringify(data));
        });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setActiveContinent(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetch('/doc/map/machineZone.json')
      .then(response => response.json())
      .then(data => {
        setAllData(data);
      });
  }, []);

  useEffect(() => {
    if (activeContinent) {
      setTranslatedName(t(continentNames[activeContinent.name]));
    }
  }, [activeContinent, t]);

  /**
 * Get spaced colors from a list of colors.
 * 
 * @param {Array<string>} colors  - The list of colors.
 * @param {number} count          - The number of colors needed.
 * @returns {Array<string>}       - The spaced colors.
 */
  const getSpacedColors = (colors, count) => {
    const interval = Math.floor(colors.length / count);
    return Array.from({ length: count }, (_, i) => colors[i * interval]);
  };

  /**
 * Get chart data for a continent.
 * 
 * @param {Object} continent  - The continent object.
 * @param {Object} allData    - The data for all continents.
 * @returns {Object}          - The chart data.
 */
  const getChartData = (continent, allData) => {
    const continentData = allData[continent.name];

    const labels = continentData.map(item => item.country);
    const dataChart = continentData.map(item => item.number);

    const colors = [
      '#d4b9da', '#c994c7', '#df65b0', '#e7298a', '#ce1256', '#980043', '#67001f', '#58355E', '#ffb3b3', '#ff7f00', '#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf'
    ];

    const selectedColors = getSpacedColors(colors, labels.length);

    return {
      labels: labels,
      fontColor: 'white',
      datasets: [
        {
          data: dataChart,
          backgroundColor: selectedColors,
          borderWidth: 0,
        }
      ]
    };
  };

  /**
 * Show tooltip for a continent.
 * 
 * @param {Object} geo    - The geography object.
 * @param {number} index  - The index of the geography.
 */
  const showTooltip = (geo, index) => {
    setActiveContinent(geo.properties);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 768px)'
  });

  /**
 * Handle mouse move event.
 * 
 * @param {Object} event - The mouse event.
 */
  const handleMouseMove = (event) => {
    const rect = mapContainerRef.current.getBoundingClientRect();
    setMousePosition({ x: event.clientX - rect.left, y: event.clientY - rect.top });
    setShowHoverTooltip(true);
  };

  return (
    <div className="map-container" ref={mapContainerRef}>
      <div className="title-map">{t('map.title')}</div>

      {activeContinent && chartData[activeContinent.name] && (
        <div ref={tooltipRef} className={`map-tooltip ${activeContinent ? 'active' : ''}`}>
          <div className='map-tooltip-titles'>
            <div className='map-tooltip-title'>{translatedName}</div>
            <div className='map-tooltip-close' onClick={() => setActiveContinent(null)}>x</div>
          </div>
          <div className='map-tooltip-content'>
            {chartData[activeContinent.name].datasets[0].data.length > 0 ? (
              <>
                <div className='map-tooltip-content-text'>
                  <div>{t('map.total')} : {chartData[activeContinent.name].datasets[0].data.reduce((a, b) => a + b, 0)}</div>
                  <div>{t('map.filter')}</div>
                </div>
                <div className='map-tooltip-content-chart'>
                  <Pie
                    data={chartData[activeContinent.name]}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          labels: {
                            color: legendTextColor,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <div className='map-tooltip-nochart'>{t('map.noMachines')}</div>
            )}
          </div>
        </div>
      )}

      {showHoverTooltip && (
        <div
          className="hover-tooltip"
          style={{
            top: `${mousePosition.y + 20}px`,
            left: `${mousePosition.x + 15}px`,
          }}
        >
          {t('map.tooltip')}
        </div>
      )}

      {isDesktopOrLaptop && geoUrl ? (
        <ComposableMap className='worldmap-container'
          projectionConfig={{
            rotate: [0, 0, 0],
            scale: 205,
            translate: [800 / 2, 450 / 2]
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo, index) => (
                <React.Fragment key={geo.rsmKey}>
                  <Geography
                    geography={geo}
                    onClick={() => {
                      if (activeContinent && activeContinent.id === geo.properties.id) {
                        setActiveContinent(null);
                      } else {
                        showTooltip(geo, index);
                      }
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={() => {
                      setShowHoverTooltip(false);
                    }}
                    className={`map ${activeContinent && activeContinent.id === geo.properties.id ? "map-active" : "map-default"}`}
                  />
                </React.Fragment>
              ))
            }
          </Geographies>
        </ComposableMap>
      ) : (
        <div className='map-subtext'>
          {t('map.subtext')}
        </div>
      )}
    </div>
  );
};