/////////////////////////////////////////////
//Dependencies
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useTranslation } from 'react-i18next';

/////////////////////////////////////////////
//CSS
import './AutoSuggestMachine.css';

/////////////////////////////////////////////
//Constants

/* Machine list */
const machineNameList = [
  {
    titleKey: 'category.MEB-Ebeam.name',
    machines: [
      'MEB 300',
      'MEB 300 S',
      'MEB 400',
      'MEB 400 S',
      'MEB 400 SL',
      'MEB 450',
      'MEB 450 S',
      'MEB 450 SL UHV',
      'MEB 500',
      'MEB 500 SL',
      'MEB 550',
      'MEB 550 S',
      'MEB 550 B',
      'MEB 550 S2-I',
      'MEB 550 S2-I UHV',
      'MEB 550 S2-III UHV',
      'EB 550 S3',
      'MEB 550 S4-I UHV',
      'MEB 550 S4-II UHV',
      'MEB 550 SL',
      'MEB 550 SL UHV',
      'MEB 550 SL3 UHV',
      'MEB 550 SL4 UHV',
      'MEB 600',
      'MEB 600 S',
      'MEB 600 SL UHV',
      'MEB 650',
      'MEB 650 S',
      'MEB 700 S HV',
      'MEB 700 S2-I UHV',
      'MEB 700 S2-II UHV',
      'MEB 700 SL4 UHV',
      'MEB 800',
      'MEB 800 S',
      'MEB 800 S2-III',
      'MEB 800 S2-III UHV',
      'MEB 800 SL'
    ]
  },
  {
    titleKey: 'category.MP-Sputtering.name',
    machines: [
      'MP 300',
      'MP 300 S',
      'MP 350',
      'MP 350 S',
      'MP 400',
      'MP 400 S',
      'MP 450',
      'MP 450 S',
      'MP 500',
      'MP 500 S',
      'MP 550',
      'MP 550 S',
      'MP 550 S2',
      'MP 600',
      'MP 600 S',
      'MP 600 S UHV',
      'MP 650 B',
      'MP 650 S',
      'MP 700',
      'MP 700 S',
      'MP 700 S HV',
      'MP 700 S UHV',
      'MP 800 S',
      'MP 800 S2 UHV',
      'MP 850',
      'MP 900',
      'MP 900 S',
      'MP 1000 SL'
    ]
  },
  {
    titleKey: 'category.CVD Diamond.name',
    machines: [
      'BJS 150',
      'SSDR 150',
      'SSDR 400'
    ]
  },
  {
    titleKey: 'category.ME-Thermal.name',
    machines: [
      'ME 300',
      'ME 300 B',
      'ME 320',
      'ME 400',
      'ME 400 B',
      'ME 450 S',
      'ME 450 S-IN',
      'ME 550 B',
      'ME 600 SL'
    ]
  },
  {
    titleKey: 'MU',
    machines: [
      'MU 350',
      'MU 400',
      'MU 450',
      'MU 600 S',
      'MU 700'
    ]
  },
  {
    titleKey: 'contact.autoSuggestMachine.RIE',
    machines: [
      'MG 200',
      'MG 200 S',
      'MG 300',
      'MG 300 S'
    ]
  },
  {
    titleKey: 'contact.autoSuggestMachine.milling',
    machines: [
      'MDS 150',
      'MDS 250',
      'MDS 150',
      'MGDS 150'
    ]
  },
  {
    titleKey: 'contact.autoSuggestMachine.other',
    machines: [
      'contact.autoSuggestMachine.other',
    ]
  },
];

/////////////////////////////////////////////
//Functions

/**
 * Normalize the given string by converting it to lowercase and removing spaces and special characters.
 * 
 * @param {string} str  - The string to normalize.
 * @returns {string}    - Returns the normalized string.
 */
function normalizeString(str) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '') // Supprime les espaces
    .replace(/[.*+?^${}()|[\]\\]/g, ''); // Supprime les caractères spéciaux
}

/**
 * Verify if the normalized value is a partial match of the normalized target.
 * 
 * @param {string} normalizedValue  - The normalized value to search for.
 * @param {string} normalizedTarget - The normalized target to search in.
 * @returns {boolean}               - Returns true if the normalized value is a partial match of the normalized target.
 */
function isPartialMatch(normalizedValue, normalizedTarget) {
  const parts = normalizedValue.split(/\s+/); // Divise la chaîne de recherche en parties
  return parts.every(part => normalizedTarget.includes(part));
}

/**
 * Get suggestions based on the input value.
 * 
 * @param {string} value  - The input value to get suggestions for.
 * @param {function} t    - The translation function.
 * @returns {Array}       - Returns an array of suggestion sections.
 */
function getSuggestions(value, t) {
  const normalizedValue = normalizeString(value.trim());

  if (normalizedValue === '') {
    return machineNameList;
  }

  const suggestions = machineNameList
    .map(section => {
      const titleMatches = isPartialMatch(normalizedValue, normalizeString(t(section.titleKey)));
      const filteredMachines = section.machines ? section.machines.filter(machine => isPartialMatch(normalizedValue, normalizeString(machine))) : [];
      return {
        titleKey: section.titleKey,
        machines: titleMatches ? section.machines : filteredMachines
      };
    })
    .filter(section => section.machines.length > 0);

  // Toujours inclure la section "contact.autoSuggestMachine.other" si elle n'est pas déjà incluse
  const otherSection = machineNameList.find(section => section.titleKey === 'contact.autoSuggestMachine.other');
  const isOtherSectionIncluded = suggestions.some(section => section.titleKey === 'contact.autoSuggestMachine.other');
  if (otherSection && !isOtherSectionIncluded) {
    suggestions.push(otherSection);
  }

  return suggestions;
}

/**
 * Get the value of a suggestion.
 * 
 * @param {string} suggestion   - The suggestion to get the value from.
 * @param {function} t          - The translation function.
 * @returns {string}            - Returns the translated suggestion value.
 */
function getSuggestionValue(suggestion, t) {
  return t(suggestion);
}

/**
 * Render a suggestion.
 * 
 * @param {string} suggestion             - The suggestion to render.
 * @param {object} param1                 - The rendering parameters.
 * @param {string} param1.query           - The query string.
 * @param {boolean} param1.isHighlighted  - Whether the suggestion is highlighted.
 * @param {function} t                    - The translation function.
 * @returns {JSX.Element}                 - Returns the rendered suggestion.
 */
function renderSuggestion(suggestion, { query, isHighlighted }, t) {
  return (
    <span>{t(suggestion)}</span>
  );
}

/**
 * Render the title of a section.
 * 
 * @param {object} section  - The section to render the title for.
 * @param {function} t      - The translation function.
 * @returns {JSX.Element}   - Returns the rendered section title.
 */
function renderSectionTitle(section, t) {
  return (
    <strong>{t(section.titleKey)}</strong>
  );
}

/**
 * Get the suggestions of a section.
 * 
 * @param {object} section  - The section to get suggestions from.
 * @returns {Array}         - Returns an array of suggestions.
 */
function getSectionSuggestions(section) {
  return section.machines;
}

////////////////////////////////////////////////////////////////////////////::
//Component

/**
 * AutoSuggestMachine component for suggesting machine names.
 * 
 * @param {Object} props                        - The component props.
 * @param {string} props.machineName            - The current machine name.
 * @param {function} props.setMachineName       - Function to set the machine name.
 * @param {function} props.handleChangeFormData - Function to handle form data changes.
 * @returns {JSX.Element}                       - The rendered AutoSuggestMachine component.
 */
export default function AutoSuggestMachine({ machineName, setMachineName, handleChangeFormData }) {
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation();

  /**
 * Handle change in the input field.
 * 
 * @param {Object} event            - The change event.
 * @param {Object} param1           - The new value object.
 * @param {string} param1.newValue  - The new value of the input field.
 */
  const handleChange = (event, { newValue }) => {
    setMachineName(newValue);
    handleChangeFormData({ target: { name: 'machineName', value: newValue } });
  };

  /**
   * Handle fetching of suggestions.
   * 
   * @param {Object} param0       - The request object.
   * @param {string} param0.value - The current input value.
   */
  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, t));
  };

  /**
   * Handle clearing of suggestions.
   */
  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  /* Input properties */
  const inputProps = {
    placeholder: "",
    value: machineName,
    onChange: handleChange,
    required: true,
  };

  ////////////////////////////////////////////////////////////////////////////

  return (
    <Autosuggest
      multiSection={true}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => getSuggestionValue(suggestion, t)}
      renderSuggestion={(suggestion, { query, isHighlighted }) => renderSuggestion(suggestion, { query, isHighlighted }, t)}
      renderSectionTitle={(section) => renderSectionTitle(section, t)}
      getSectionSuggestions={getSectionSuggestions}
      inputProps={inputProps}
    />
  );
};