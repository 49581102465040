/**
 * Supprime tous les symboles de formatage du texte.
 * 
 * @param {string} str - Le texte à nettoyer.
 * @returns {string} - Le texte nettoyé sans symboles de formatage.
 */
const ReverseParseText = (str) => {
    return str
        .replace(/\*\*(.*?)\*\*/g, '$1')  // Supprime le gras
        .replace(/\$(.*?)\$/g, '$1')      // Supprime l'italique
        .replace(/__(.*?)__/g, '$1')      // Supprime le soulignement
        .replace(/\[\[(.*?)\]\]/g, '$1')  // Supprime les liens
        .replace(/##(.*?)##(.*?)##/g, '$2') // Supprime les liens cachés
        .replace(/!\[(.*?)\]\((.*?)\)/g, '$1') // Supprime les images
        .replace(/(\|\|)(.*?)(\|\|)/g, (match, p1, p2) => {
            return p2.split('--').join(' '); // Supprime les listes
        })
        .replace(/<br \/>/g, ' ') // Supprime les sauts de ligne
        .replace(/\/\/+/g, ' ') // Supprime les "////"
        .replace(/<\/?[^>]+(>|$)/g, ""); // Supprime toutes les balises HTML restantes
};

export default ReverseParseText;