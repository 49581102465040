/////////////////////////////////////////////
//Dependencies
import { React } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

/////////////////////////////////////////////
//CSS
import "./css/Diamond.css";
import "./css/VerticalTL.css";

/////////////////////////////////////////////
//Components
import ParseText from '../Modules/parseText/ParseText';
import ReverseParseText from '../Modules/reverseParseText/ReverseParseText';

import BannerDomain from '../Modules/bannerdomain/BannerDomain';
import HistoryDomain from '../Modules/historyDomain/HistoryDomain';
import GalleryRollAdd from '../Modules/galleryRoll/GalleryRollAdd';

//////////////////////////////////////////////////////////////////////////////////////////


export default function Diamond() {
    const { t } = useTranslation();
    const pageTitle = "Plassys ▪ " + t("diamond.name");
    const pageDescription = ReverseParseText(t('diamond.history.text1'));

    const contentStyleImportant = {
        background: '#B52A7D',
        color: '#fff',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        padding: '10px',
        paddingLeft: '10px'
    };

    const contentStyle = {
        background: '#d88eba',
        color: '#fff',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        padding: '10px',
        paddingLeft: '10px'
    };

    const iconStyleImportant = {
        background: '#B52A7D',
        color: '#fff',
        display: 'none'
    };

    const iconStyle = {
        background: '#d88eba',
        color: '#fff',
        display: 'none'
    };

    const contentArrowStyleImportant = {
        borderRight: '7px solid  #B52A7D'
    };

    const contentArrowStyle = {
        borderRight: '7px solid  #d88eba'
    };

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href="https://plassys.com/diamond" />
            </Helmet>

            <div className='diamtek-container-page'>

                {/*********************************************************************************** 
            Modify BannerDomain :

            <BannerDomain
              logo="Logo of the domain"                                (Put image in /public/img/logo)
              text="Text for the domain"                               (string)
              imagebg="Image background of the domain"                 (Put image in /public/img/assets)
            />

            ***********************************************************************************/}

                <BannerDomain
                    logo='diamtek'
                    text={t('diamond.subtitle')}
                    imagebg='Diamtek-9.jpg'
                />

                <div className="diamtek-body">

                    {/*********************************************************************************** 
                Modify HistoryDomain :
          
                <HistoryDomain
                name="Name of Domain"                                    (string)
                    text="Text for the domain"                               (string)
                    text2="Text for the domain"                              (string)
                    image="Image of the domain"                              (Put image in /public/img/assets)
                />

                Tips for text and text2:
                - Use '**' to bold a text: **bold**
                - Use '$' to italic a text: $italic$
                - Use '?' to underline a text: ?underline?
                You can combine them: **$?bold italic underline$?**
                ***********************************************************************************/}

                    <HistoryDomain
                        name='DIAMTEK'
                        text={t('diamond.history.text1')}
                        text2={t('diamond.history.text2')}
                        // image='Diamtek4.jpg'
                        image='Diamtek-5.jpg'
                    />

                    <div className='diamtek-timeline'>
                        <div className='diamtek-timeline-title'>
                            {t('diamond.timeline.title')}
                        </div>
                        <div className='diamtek-timeline-content'>
                            {/* <div className='diamtek-timeline-date'>
                            <ul>
                                <li className='important'>2006 : {t('diamond.timeline.2006')}</li>
                                <li className='important'>2007 : {t('diamond.timeline.2007')}</li>
                                <li className='important'>2007-2013 : {t('diamond.timeline.2007-2013')}</li>
                                <li className='important'>2012 : {t('diamond.timeline.2012')}</li>
                                <li className='important'>2012-2014 : {t('diamond.timeline.2012-2014')}</li>
                                <li className='important'>2014 : {t('diamond.timeline.2014')}</li>
                                <li className='important'>2015 : {t('diamond.timeline.2015')}</li>
                                <li className='important'>2016 : {t('diamond.timeline.2016')}</li>
                                <li className='important'>2017 : {t('diamond.timeline.2017')}</li>
                                <li className='important'>2018 : {t('diamond.timeline.2018')}</li>
                                <li className='important'>2019 : {t('diamond.timeline.2019')}</li>
                                <li>2020 : {t('diamond.timeline.2020')}</li>
                                <li>2021 : {t('diamond.timeline.2021-1')}</li>
                                <li>2021 : {t('diamond.timeline.2021-2')}</li>
                                <li className='important'>2021 : {t('diamond.timeline.2021-3')}</li>
                                <li>2022 : {t('diamond.timeline.2022')}</li>
                                <li>2023 : {t('diamond.timeline.2023-1')}</li>
                                <li>2023 : {t('diamond.timeline.2023-2')}</li>
                            </ul>
                        </div> */}
                            <div className='diamtek-timeline-image'>
                            </div>
                            <div className='qutek-brief-history-timelinestart'>
                                <VerticalTimeline>

                                    {/* 2006 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2006</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2006")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2007 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2007</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2007")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2007-2013 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2007-2013</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2007-2013")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2012 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2012</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2012")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2012-2014 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2012-2014</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2012-2014")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2014 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2014</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2014")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2015 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2015</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2015")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2016 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2016</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2016")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2017 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2017</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2017")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2018 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2018</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2018")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2019 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2019</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2019")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2020 - 2021 */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyle}
                                        contentArrowStyle={contentArrowStyle}
                                        iconStyle={iconStyle}
                                    >
                                        <h3 className="vertical-timeline-element-title">2020</h3>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2020")}</p>
                                        <h3 className="vertical-timeline-element-title">2021</h3>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2021-1")}</p>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2021-2")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2021 important */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyleImportant}
                                        contentArrowStyle={contentArrowStyleImportant}
                                        iconStyle={iconStyleImportant}
                                    >
                                        <h3 className="vertical-timeline-element-title-important">2021</h3>
                                        <p className="vertical-timeline-element-texte-important">{t("diamond.timeline.2021-3")}</p>
                                    </VerticalTimelineElement>

                                    {/* 2022 - 2023 */}
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={contentStyle}
                                        contentArrowStyle={contentArrowStyle}
                                        iconStyle={iconStyle}
                                    >
                                        <h3 className="vertical-timeline-element-title">2022</h3>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2022")}</p>
                                        <h3 className="vertical-timeline-element-title">2023</h3>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2023-1")}</p>
                                        <p className="vertical-timeline-element-texte">{t("diamond.timeline.2023-2")}</p>
                                    </VerticalTimelineElement>

                                </VerticalTimeline>
                            </div>
                        </div>
                    </div>

                    <div className='diamtek-galery'>
                        {/*********************************************************************************** 
                    Modify GalleryRollAdd :
            
                    <GalleryRollAdd
                        title="Title of the Accordion"                                      (string)
                        images={[ src : "path of the image",                                (choose image in public/...)
                                    width : "width of the image",                           (int in format x:1, x:9, ...)
                                    height : "height of the image",                         (int in format 1:x, 16:x, ...)
                                    alt : "alt of the image",                               (string)
                                    title : "title of the image",                           (string)
                                    key : "key of the image"                                (string unique)
                                ]}
                    />
                    ***********************************************************************************/}
                        <GalleryRollAdd
                            title={t('diamond.gallery')}
                            photoPaths={[
                                "/img/assets/Diamtek-1.jpg",
                                "/img/assets/Diamtek-2.jpg",
                                "/img/assets/Diamtek-3.jpg",
                                "/img/assets/Diamtek-4.jpg",
                                "/img/assets/Diamtek-5.jpg",
                                "/img/assets/Diamtek-6.jpg",
                                "/img/assets/Diamtek-7.jpg",
                                "/img/assets/Diamtek-8.jpg",
                                "/img/assets/Diamtek-9.jpg",

                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 6.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 7.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 1.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 2.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 3.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 5.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 8.jpg",
                                "/img/machines/CVD Diamant/SSDR 150/CVD Diamant - PLASSYS SSDR 150 - 15.jpg",
                                "/img/machines/CVD Diamant/SSDR 400/CVD Diamant - PLASSYS SSDR 400 - 3.jpg",
                                "/img/machines/CVD Diamant/SSDR 400/CVD Diamant - PLASSYS SSDR 400 - 5.jpg"
                            ]}
                        />
                    </div>

                    <div className='diamtek-gotoCVD-container'>
                        <NavLink to="/categories/CVD%20Diamond" className="diamtek-gotoCVD-link">
                            <button className="diamtek-annexe-contact-button diamtek-gotoCVD-button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                                </svg>
                                <div className="diamtek-annexe-contact-button-text">
                                    {t('navbar.Systems')} - {t('navbar.SystemsDD.CVD')}
                                </div>
                            </button>
                        </NavLink>
                    </div>

                    <div className='diamtek-annexe'>
                        <div className='diamtek-annexe-document'>

                            <div className='diamtek-annexe-document-title'>
                                Documents
                            </div>

                            <div className='diamtek-annexe-document-download-list'>

                                <div className='qutek-annexe-document-download-item'>
                                    <div className='qutek-annexe-document-download-item-title'>
                                        Overview of machines categories
                                    </div>
                                    <a className='qutek-annexe-document-download-item-button' href='/doc/PlassysOverview.pdf' download>
                                        {t('annexes.download')}
                                        <div className='qutek-annexe-document-download-item-button-icon'>
                                        </div>
                                    </a>
                                </div>

                                {/* <hr></hr> */}

                            </div>

                        </div>

                        <div className='diamtek-annexe-line'></div>

                        <div className='diamtek-annexe-contact'>

                            <ParseText text={t("annexes.contact-text")} classN={"diamtek-annexe-contact-text"} />

                            <NavLink to="/contact" className="diamtek-annexe-contact-link">
                                <button className="diamtek-annexe-contact-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                                    </svg>
                                    <div className="diamtek-annexe-contact-button-text">
                                        {t('annexes.contact-button')}
                                    </div>
                                </button>
                            </NavLink>

                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
