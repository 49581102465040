/////////////////////////////////////////////
//Dependencies
import React from "react";

/////////////////////////////////////////////

/**
 * MachineImgAdd component
 * 
 * @param {Object} props            - The component props.
 * @param {string} props.image      - The name of the image with extension.
 * @param {string} props.alt        - The alt text for the image.
 * @param {function} props.onClick  - The function to call when the image is clicked.
 * @returns {JSX.Element}           - The rendered MachineImgAdd component.
 */
export default function MachineImgAdd({ image, alt, onClick }) {
  return (
    <div className="product-image-container">
      <img className="product-image" src={`../../img/machines/${image}`} alt={alt} onClick={onClick} />
    </div>
  );
};