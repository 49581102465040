/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

/////////////////////////////////////////////
//CSS
import "../Modules/productsCat/ProductsCat.css";


/////////////////////////////////////////////
//Imported Components
import Products from "../Modules/productsCat/ProductsCat";

//////////////////////////////////////////////////////////////////////////////////////////

function Categories() {
  const { t } = useTranslation();
  const pageTitle = "Plassys ▪ " + t("navbar.Systems");
  const pageDescription = t('categoryPage.title');

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://plassys.com/categories" />
      </Helmet>
      
      <Products />
    </>
  );
}
export default Categories;
