/////////////////////////////////////////////
//Dependencies
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

/////////////////////////////////////////////
//CSS
import './Sitemap.css';
import '@fortawesome/fontawesome-free/css/all.css';

/////////////////////////////////////////////
//Component

/**
 * Sitemap component
 * 
 * @returns {JSX.Element} - The rendered Sitemap component.
 */
export default function Sitemap() {
    const { t } = useTranslation();
    const categories = t("category", { returnObjects: true });
    const machines = t("machines", { returnObjects: true });
    // Use useState to keep track of the visibility of the children
    const [visibleChildren, setVisibleChildren] = useState({});

    /**
     * Function to toggle the visibility of the children
     * 
     * @param {string} categoryName - The name of the category to toggle.
     */
    const handleToggle = (categoryName) => {
        setVisibleChildren((prevVisibleChildren) => ({
            ...prevVisibleChildren,
            [categoryName]: !prevVisibleChildren[categoryName],
        }));
    };

    /**
 * Get machines by category
 * 
 * @param {string} categoryName - The name of the category.
 * @returns {Array<Object>} - The list of machines in the category.
 */
    const getMachinesByCategory = (categoryName) => {
        // console.log('machines', machines);
        return Object.values(machines).filter(machine => machine.tags && machine.tags.includes(categoryName));
    };

    return (
        <div className='sitemap-container'>
            <div className='title-sm'>{t('footer.link4-1')}</div>

            <div id="collapseDVR3" className="panel-collapse collapse in">
                <div className="tree">
                    <ul>

                        {/*Home*/}
                        <li>
                            <Link to="/" className='linkSM'><span className='itemSM'>{t('navbar.Home')} </span></Link>
                        </li>

                        {/*Presentation*/}
                        <li>
                            <Link to="/presentation" className='linkSM'><span className='itemSM'> {t('navbar.Company')} </span></Link>
                        </li>

                        {/*Categories and Products*/}
                        <li className={visibleChildren['Systems'] ? 'parent_li open' : 'parent_li'}>
                            <span className='itemSM' onClick={() => handleToggle('Systems')}>
                                <i className={visibleChildren['Systems'] ? 'sitemap fa fa-minus-square' : 'sitemap fa fa-plus-square'}></i>{' '}
                                {t('navbar.Systems')}
                            </span>

                            {/*List of categories*/}
                            <ul style={{ display: visibleChildren['Systems'] ? 'block' : 'none' }}>
                                {Object.values(categories).map((category, categoryIndex) => {
                                    const machinesInCategory = getMachinesByCategory(category.name);
                                    if (category.name === 'Software' || category.name === 'Logiciel') {
                                        return (
                                            <li key={categoryIndex}>
                                                <Link to="/categories/Software" className='linkSM'>
                                                    <span className='itemSM'>{category.name}</span>
                                                </Link>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li key={categoryIndex} className={visibleChildren[category.name] ? 'parent_li open' : 'parent_li'}>
                                                <span className='itemSM' onClick={() => handleToggle(category.name)}>
                                                    <i className={visibleChildren[category.name] ? 'sitemap fa fa-minus-square' : 'sitemap fa fa-plus-square'}></i>{' '}
                                                    {category.name}
                                                </span>

                                                {/*List of machines in category*/}
                                                <ul style={{ display: visibleChildren[category.name] ? 'block' : 'none' }}>
                                                    {machinesInCategory.map((machine, machineIndex) => (
                                                        <li key={machineIndex}>
                                                            <Link to={`/categories/${category.link}/${machine.link}`} className='linkSM'>
                                                                <span className='itemSM'>{machine.name}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </li>

                        {/*Qutek*/}
                        <li>
                            <Link to="/quantum" className='linkSM'><span className='itemSM'>{t('navbar.Quantum')} </span></Link>
                        </li>

                        {/*Diamtek*/}
                        <li>
                            <Link to="/diamond" className='linkSM'><span className='itemSM'>{t('diamond.name')} </span></Link>
                        </li>

                        {/*Contact*/}
                        <li className={visibleChildren[2] ? 'parent_li open' : 'parent_li'}>
                            <span className='itemSM' onClick={() => handleToggle(2)}>

                                <i className={visibleChildren[2] ? 'sitemap fa fa-minus-square' : 'sitemap fa fa-plus-square'}></i> {t('navbar.Contact')}
                            </span>
                            <ul style={{ display: visibleChildren[2] ? 'block' : 'none' }}>
                                {/* Global */}
                                <li>
                                    <Link to="/contact?service=Global" className='linkSM'><span className='itemSM'> Global </span></Link>
                                </li>

                                {/* Technical */}
                                <li>
                                    <Link to="/contact?service=Technical" className='linkSM'><span className='itemSM'> {t('navbar.ContactDD.Technical')}</span></Link>
                                </li>

                                {/* Commercial */}
                                <li>
                                    <Link to="/contact?service=Sales" className='linkSM'><span className='itemSM'> {t('navbar.ContactDD.Commercial')} </span></Link>
                                </li>

                                {/* Distributor */}
                                <li>
                                    <Link to="/contact/distributors" className='linkSM'><span className='itemSM'> {t('navbar.ContactDD.Distributor')} </span></Link>
                                </li>

                                {/* Recruitment */}
                                <li>
                                    <Link to="/recruitment" className='linkSM'><span className='itemSM'>{t('footer.link4-3')}</span></Link>
                                </li>
                            </ul>
                        </li>

                        {/*Other*/}
                        <li className={visibleChildren[3] ? 'parent_li open' : 'parent_li'}>
                            <span className='itemSM' onClick={() => handleToggle(3)}>
                                <i className={visibleChildren[3] ? 'sitemap fa fa-minus-square' : 'sitemap fa fa-plus-square'}></i> {t('footer.title4')}
                            </span>
                            <ul style={{ display: visibleChildren[3] ? 'block' : 'none' }}>
                                {/*Gallery*/}
                                <li>
                                    <Link to="/gallery" className='linkSM'><span className='itemSM'>{t('footer.link4-2')} </span></Link>
                                </li>

                                {/*About*/}
                                <li>
                                    <Link to="/presentation" className='linkSM'><span className='itemSM'>{t('footer.linkbot1')}</span></Link>
                                </li>

                                {/*Site map (here)*/}
                                <li>
                                    <Link to="" className='linkSM'><span className='itemSM'>{t('footer.link4-1')}</span></Link>
                                </li>

                                {/*Privacy Policy*/}
                                <li>
                                    <Link to="/privacypolicy" className='linkSM'><span className='itemSM'>{t('footer.linkbot5')}</span></Link>
                                </li>

                                {/*LinkedIn*/}
                                <li>
                                    <Link to="https://www.linkedin.com/company/plassys-bestek/" className='linkSM'><span className='itemSM'>LinkedIn</span></Link>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    );
};