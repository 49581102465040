/*version avec toutes les images dans le dossier machines*/
/*dossier machines*/
/*titre juste avec les noms des images*/

/////////////////////////////////////////////
//Dependencies
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PhotoAlbum from "react-photo-album";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

/////////////////////////////////////////////
//CSS
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "./css/Gallery.css";

/////////////////////////////////////////////
//Components
import Loading from '../Modules/loading/Loading';

/////////////////////////////////////////////

function Gallery() {
   const [index, setIndex] = useState(-1);
   const { t } = useTranslation();
   const pageTitle = "Plassys ▪ " + t("gallery.title");
   const pageDescription = "PLASSYS - " + t("gallery.title");
   const [photos, setPhotos] = useState([]);
   const [loading, setLoading] = useState(false);

   const [searchTerm, setSearchTerm] = useState("");
   const [filteredPhotos, setFilteredPhotos] = useState([]);
   const [hasMore, setHasMore] = useState(true);

   const clearSearch = () => {
      setSearchTerm('');
   };

   const loadImages = async () => {
      setLoading(true);

      const machines = t('machines', { returnObjects: true });
      const loadedPhotos = [];
      let loadedCount = 0;
      const totalImages = Object.keys(machines).reduce((acc, machineKey) => acc + machines[machineKey].image.length, 0);

      const loadPromises = Object.keys(machines).flatMap(machineKey => {
         const machine = machines[machineKey];
         return machine.image.map((photoPath, index) => {
            return new Promise((resolve) => {
               const img = new Image();
               img.src = `/img/machines/${photoPath}`;

               img.onload = () => {
                  loadedPhotos.push({
                     src: img.src,
                     width: img.naturalWidth,
                     height: img.naturalHeight,
                     title: photoPath.split("/").pop().split(".")[0].replace(/_/g, " "),
                     key: `${photoPath}-${index}`
                  });
                  loadedCount++;
                  const progress = Math.round((loadedCount / totalImages) * 100);
                  console.log(`Loading progress: ${progress}%`);
                  document.getElementById('loading-progress').innerText = `Loading... ${progress}%`;
                  resolve();
               };

               img.onerror = () => {
                  loadedCount++;
                  const progress = Math.round((loadedCount / totalImages) * 100);
                  console.log(`Loading progress: ${progress}%`);
                  document.getElementById('loading-progress').innerText = `Loading... ${progress}%`;
                  resolve(); // Resolve even if the image fails to load
               };
            });
         });
      });

      await Promise.all(loadPromises);

      setPhotos(loadedPhotos);
      setLoading(false);
      document.getElementById('loading-progress').style.display = 'none';
      console.log("Loaded", loadedCount, "images");
      console.log("Loading : ", loading);
   };

   useEffect(() => {
      loadImages();
   }, []);

   useEffect(() => {
      const normalizeString = (str) => str.toLowerCase().replace(/[\s-]/g, '');

      const normalizedSearchTerm = normalizeString(searchTerm);

      setFilteredPhotos(
         photos.filter(photo =>
            normalizeString(photo.title).includes(normalizedSearchTerm)
         )
      );
   }, [photos, searchTerm]);

   const fetchMorePhotos = () => {
      if (filteredPhotos.length >= photos.length) {
         setHasMore(false);
         return;
      }

      setFilteredPhotos(prevPhotos => [
         ...prevPhotos,
         ...photos.slice(prevPhotos.length, prevPhotos.length + 15)
      ]);
   };

   return (
      <>
         <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <link rel="canonical" href="https://plassys.com/gallery" />
         </Helmet>

         <div className="gallery-page">
            {loading ? (
               <>
                  <Loading />
                  <div id="loading-progress"></div>
               </>
            ) : (
               <>
                  <h1 className="gallery-title">{t("gallery.title")}</h1>
                  <div className="search-bar-container">
                     <input
                        type="text"
                        className="search-bar-item"
                        placeholder={t('categoryPage.search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                     />
                     {searchTerm ? (
                        <ClearIcon
                           className="search-icon"
                           onClick={clearSearch}
                        />
                     ) : (
                        <SearchIcon className="search-icon" />
                     )}
                  </div>

                  <div className="gallery-container">
                     <InfiniteScroll
                        dataLength={filteredPhotos.length}
                        next={fetchMorePhotos}
                        hasMore={hasMore}
                        loader={<p className='gallery-title'>Loading...</p>}
                        endMessage={
                           <p className='gallery-title'>
                              <b>End</b>
                           </p>
                        }
                     >
                        <PhotoAlbum
                           photos={filteredPhotos}
                           layout="rows"
                           targetRowHeight={250}
                           onClick={({ index }) => setIndex(index)}
                           breakpoints={[600, 900, 1500]}
                           loading="lazy"
                        />
                     </InfiniteScroll>

                     <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        padding={10}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                     />
                  </div>
               </>
            )}
         </div>

      </>
   );
}

export default Gallery;