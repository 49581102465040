/////////////////////////////////////////////
//Dependencies
import React, { useState } from "react";

/////////////////////////////////////////////

/**
 * NavActivbar component
 * 
 * @returns {JSX.Element} - The rendered NavActivbar component.
 */
export default function NavActivbar() {
  const [activeBarStyle, setActiveBarStyle] = useState({
    visibility: "visible",
    width: 0,
    left: 0,
    transition: "",
  });

return <div className="active-bar" style={activeBarStyle}></div>;

};