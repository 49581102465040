/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/////////////////////////////////////////////
//CSS
import "./css/Recruitment.css";
import { Link } from "react-router-dom";

/////////////////////////////////////////////
//Components

//////////////////////////////////////////////////////////////////////////////////////////

export default function PrivacyPolicy() {
    const { t } = useTranslation();
    const pageTitle = `Plassys ▪ ${t("footer.linkbot5")}`;

    return (

        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>

            <div className="recrut-container">

                <h1 className="recrut-title">PRIVACY POLICY AND COOKIES</h1>
                <div className="recrut-content" id="pp">
                    <span className="recrut-text">Last updated June 17, 2024</span>

                    <div className="recrut-text">

                        <p>
                            This privacy notice for Plassys Bestek (doing business as Plassys) ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                            Visit our website at https://plassys.com, or any website of ours that links to this privacy notice
                            Engage with us in other related ways, including any sales, marketing, or events
                            Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at jp.fauvarque@plassys.com.
                        </p>
                        <p>
                            Please note that some images on our website have been enhanced using artificial intelligence technologies to improve their visual quality. These enhancements may include:
                            <ul>
                                <li>Improving resolution and sharpness</li>
                                <li>Color and contrast correction</li>
                                <li>Noise and artifact reduction</li>
                            </ul>
                            Additionally, some images have been manually retouched to mask sensitive information or to enhance their graphical appearance. These retouches may include:
                            <ul>
                                <li>Masking or blurring confidential information</li>
                                <li>Adjusting brightness and contrast</li>
                                <li>Modifying graphical elements for better visual integration</li>
                            </ul>
                            We are committed to respecting copyright and privacy during these enhancement and retouching processes.
                        </p>
                    </div>

                    <div className="recrut-subtitle">SUMMARY OF KEY POINTS :</div>
                    <div className="recrut-text">
                        <p>
                            This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                        </p>
                        <p>
                            What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.

                        </p>
                        <p>
                            Do we process any sensitive personal information? We do not process sensitive personal information.
                        </p>
                        <p>
                            Do we collect any information from third parties? We do not collect any information from third parties.
                        </p>
                        <p>
                            How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
                        </p>
                        <p>
                            In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.
                        </p>
                        <p>
                            How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
                        </p>
                        <p>
                            What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.
                        </p>
                        <p>
                            How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </p>
                        <p>
                            Want to learn more about what we do with any information we collect? Review the privacy notice in full.
                        </p>


                        <div className="recrut-subtitle">TABLE OF CONTENTS :</div>
                        <ul>
                            <li>1. WHAT INFORMATION DO WE COLLECT?</li>
                            <li>2. HOW DO WE PROCESS YOUR INFORMATION?</li>
                            <li>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
                            <li> 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                            <li>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                            <li>6. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                            <li>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                            <li>8. DO WE COLLECT INFORMATION FROM MINORS?</li>
                            <li>9. WHAT ARE YOUR PRIVACY RIGHTS?</li>
                            <li>10. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                            <li>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                            <li>12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                            <li>13. DO WE MAKE UPDATES TO THIS NOTICE?</li>
                            <li>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                            <li>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                        </ul>

                        <div className="recrut-subtitle">1. WHAT INFORMATION DO WE COLLECT? :</div>
                        <h2>Personal information you disclose to us</h2>
                        <p>
                            In Short: We collect personal information that you provide to us.
                        </p>
                        <p>
                            We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                        </p>
                        <p>
                            Sensitive Information. We do not process sensitive information.
                        </p>
                        <p>
                            All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                        </p>

                        <h2>Information automatically collected</h2>

                        <p>
                            In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                        </p>

                        <p>
                            We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                        </p>

                        <p>
                            Like many businesses, we also collect information through cookies and similar technologies.
                        </p>

                        <p>
                            The information we collect includes:
                        </p>

                        <ul>
                            <li>
                                Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
                            </li>
                        </ul>

                        <div className="recrut-subtitle">2. HOW DO WE PROCESS YOUR INFORMATION? :</div>
                        <p>
                            In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                        </p>

                        <p>
                            We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                        </p>

                        <ul>
                            <li>
                                To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                            </li>
                            <li>
                                To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                            </li>
                            <li>
                                Contact form. Contact the team in company
                            </li>
                        </ul>

                        <div className="recrut-subtitle">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? :</div>
                        <p>
                            In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
                        </p>

                        <p>
                            If you are located in the EU or UK, this section applies to you.
                        </p>

                        <p>
                            The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                        </p>

                        <ul>
                            <li>
                                Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
                            </li>
                            <li>
                                Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                            </li>
                            <li>
                                Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                                <ul>
                                    <li>
                                        Contact client / company
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                            </li>
                            <li>
                                Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                            </li>
                        </ul>

                        <p>
                            If you are located in Canada, this section applies to you.
                        </p>

                        <p>
                            We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.
                        </p>

                        <p>
                            In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                        </p>
                        <ul>
                            <li>
                                If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
                            </li>
                            <li>
                                For investigations and fraud detection and prevention
                            </li>
                            <li>
                                For business transactions provided certain conditions are met
                            </li>
                            <li>
                                If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
                            </li>
                            <li>
                                For identifying injured, ill, or deceased persons and communicating with next of kin
                            </li>
                            <li>
                                If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
                            </li>
                            <li>
                                If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                            </li>
                            <li>
                                If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
                            </li>
                            <li>
                                If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
                            </li>
                            <li>
                                If the collection is solely for journalistic, artistic, or literary purposes
                            </li>
                            <li>
                                If the information is publicly available and is specified by the regulations
                            </li>
                        </ul>

                        <div className="recrut-subtitle">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? :</div>

                        <p>
                            In Short: We may share information in specific situations described in this section and/or with the following third parties.
                        </p>

                        <p>
                            We may need to share your personal information in the following situations:
                        </p>

                        <ul>
                            <li>
                                Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                            </li>
                        </ul>

                        <div className="recrut-subtitle">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? :</div>

                        <p>
                            In Short: We may use cookies and other tracking technologies to collect and store your information.
                        </p>

                        <p>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
                        </p>

                        <p>
                            We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
                        </p>

                        <p>
                            To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
                        </p>

                        <p>
                            Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                        </p>

                        <h2>Google Analytics</h2>

                        <p>
                            We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting and Google Display Network Impressions Reporting. To opt out of being tracked by Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout. You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means include http://optout.networkadvertising.org/ and http://www.networkadvertising.org/mobile-choice. For more information on the privacy practices of Google, please visit the Google Privacy & Terms page.
                        </p>

                        <div className="recrut-subtitle">6. HOW LONG DO WE KEEP YOUR INFORMATION? :</div>

                        <p>
                            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                        </p>

                        <p>
                            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the time of communication between the service contacted in the form.
                        </p>

                        <p>
                            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                        </p>

                        <div className="recrut-subtitle">7. HOW DO WE KEEP YOUR INFORMATION SAFE? :</div>

                        <p>
                            In Short: We aim to protect your personal information through a system of organizational and technical security measures.
                        </p>

                        <p>
                            We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                        </p>

                        <div className="recrut-subtitle">8. DO WE COLLECT INFORMATION FROM MINORS? :</div>

                        <p>
                            In Short: We do not knowingly collect data from or market to children under 18 years of age.
                        </p>

                        <p>
                            We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at jp.fauvarque@plassys.com.
                        </p>

                        <div className="recrut-subtitle">9. WHAT ARE YOUR PRIVACY RIGHTS? :</div>

                        <p>
                            In Short: Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                        </p>

                        <p>
                            In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                        </p>

                        <p>
                            We will consider and act upon any request in accordance with applicable data protection laws.
                        </p>

                        <p>
                            If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.
                        </p>

                        <p>
                            If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
                        </p>

                        <p>
                            Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                        </p>

                        <p>
                            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                        </p>

                        <p>
                            Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
                        </p>

                        <p>
                            If you have questions or comments about your privacy rights, you may email us at jp.fauvarque@plassys.com.
                        </p>

                        <div className="recrut-subtitle">10. CONTROLS FOR DO-NOT-TRACK FEATURES :</div>

                        <p>
                            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                        </p>

                        <p>
                            California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
                        </p>

                        <div className="recrut-subtitle">11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? :</div>

                        <p>
                            In Short: If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
                        </p>

                        <h2>
                            Categories of Personal Information We Collect
                        </h2>

                        <p>
                            We have collected the following categories of personal information in the past twelve (12) months:
                        </p>

                        <TableContainer >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Examples</TableCell>
                                        <TableCell>Collected</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>A. Identifiers</TableCell>
                                        <TableCell>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</TableCell>
                                        <TableCell>YES</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>B. Personal information as defined in the California Customer Records statute</TableCell>
                                        <TableCell>Name, contact information, education, employment, employment history, and financial information</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>C. Protected classification characteristics under state or federal law</TableCell>
                                        <TableCell>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>D. Commercial information</TableCell>
                                        <TableCell>Transaction information, purchase history, financial details, and payment information</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>E. Biometric information</TableCell>
                                        <TableCell>Fingerprints and voiceprints</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>F. Internet or other similar network activity</TableCell>
                                        <TableCell>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</TableCell>
                                        <TableCell>YES</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>G. Geolocation data</TableCell>
                                        <TableCell>Device location</TableCell>
                                        <TableCell>YES</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>H. Audio, electronic, sensory, or similar information</TableCell>
                                        <TableCell>Images and audio, video or call recordings created in connection with our business activities</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>I. Professional or employment-related information</TableCell>
                                        <TableCell>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>J. Education Information</TableCell>
                                        <TableCell>Student records and directory information</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>K. Inferences drawn from collected personal information</TableCell>
                                        <TableCell>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>L. Sensitive personal </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>NO</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <p>
                            We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                        </p>

                        <ul>
                            <li>
                                Receiving help through our customer support channels;
                            </li>
                            <li>
                                Participation in customer surveys or contests;
                            </li>
                            <li>
                                Facilitation in the delivery of our Services and to respond to your inquiries.
                            </li>
                        </ul>

                        <p>
                            We will use and retain the collected personal information as needed to provide the Services or for:
                        </p>

                        <ul>
                            <li>
                                Category H - Never
                            </li>
                        </ul>

                        <h2>Sources of Personal Information</h2>

                        <p>Learn more about the sources of personal information we collect in "WHAT INFORMATION DO WE COLLECT?"</p>

                        <h2>How We Use and Share Personal Information</h2>

                        <p>
                            Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"
                        </p>

                        <p>
                            Will your information be shared with anyone else?
                        </p>

                        <p>
                            We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
                        </p>

                        <p>
                            We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                        </p>

                        <p>
                            We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                        </p>

                        <h2>Your Rights</h2>

                        <p>
                            You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
                        </p>

                        <ul>
                            <li>
                                Right to know whether or not we are processing your personal data
                            </li>
                            <li>
                                Right to access your personal data
                            </li>
                            <li>
                                Right to correct inaccuracies in your personal data
                            </li>
                            <li>
                                Right to request the deletion of your personal data
                            </li>
                            <li>
                                Right to obtain a copy of the personal data you previously shared with us
                            </li>
                            <li>
                                Right to non-discrimination for exercising your rights
                            </li>
                            <li>
                                Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
                            </li>
                        </ul>

                        <p>
                            Depending upon the state where you live, you may also have the following rights:
                        </p>

                        <ul>
                            <li>
                                Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)
                            </li>
                            <li>
                                Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law)
                            </li>
                            <li>
                                Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)
                            </li>
                            <li>
                                Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)
                            </li>
                        </ul>

                        <h2>How to Exercise Your Rights</h2>

                        <p>
                            To exercise these rights, you can contact us by submitting a data subject access request, by emailing us at jp.fauvarque@plassys.com, or by referring to the contact details at the bottom of this document.
                        </p>

                        <p>
                            Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
                        </p>

                        <h2>Request Verification</h2>

                        <p>
                            Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
                        </p>

                        <p>
                            If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
                        </p>

                        <h2>Appeals</h2>

                        <p>
                            Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at jp.fauvarque@plassys.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
                        </p>

                        <h2>California "Shine The Light" Law</h2>

                        <p>
                            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                        </p>

                        <div className="recrut-subtitle">12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS? :</div>

                        <p>
                            In Short: You may have additional rights based on the country you reside in.
                        </p>

                        <h2>Australia and New Zealand</h2>

                        <p>
                            We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
                        </p>

                        <p>
                            This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
                        </p>

                        <p>
                            If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to provide our services, in particular:
                        </p>

                        <ul>
                            <li>
                                offer you the products or services that you want
                            </li>
                            <li>
                                respond to or help with your requests
                            </li>
                        </ul>

                        <p>
                            At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
                        </p>

                        <p>
                            If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the Office of the Australian Information Commissioner and a breach of New Zealand's Privacy Principles to the Office of New Zealand Privacy Commissioner.
                        </p>

                        <div className="recrut-subtitle">13. DO WE MAKE UPDATES TO THIS NOTICE? :</div>

                        <p>
                            In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                        </p>

                        <p>
                            We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                        </p>

                        <div className="recrut-subtitle">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? :</div>

                        <p>
                            If you have questions or comments about this notice, you may email us at jp.fauvarque@plassys.com or contact us by post at:
                        </p>

                        <p>
                            Plassys Bestek
                            <br />14 Rue de la Gare,
                            <br />Marolles-En-Hurepoix, Essonne 91630
                            <br />France
                        </p>

                        <div className="recrut-subtitle">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? :</div>

                        <p>
                            Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.
                        </p>

                    </div>

                </div>

                <div className="recrut-content" id="cookies">
                    <div className="recrut-subtitle">COOKIES</div>
                    <span className="recrut-text">Last updated June 17, 2024</span>

                    <div className="recrut-text">
                        <p>
                            This Cookie Policy explains how Plassys Bestek ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at  https://plassys.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                        </p>

                        <p>
                            In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.
                        </p>

                        <h2>
                            What are cookies?
                        </h2>

                        <p>
                            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
                        </p>

                        <p>
                            Cookies set by the website owner (in this case, Plassys Bestek) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                        </p>

                        <h2> Why do we use cookies?</h2>

                        <p>
                            We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.
                        </p>

                        <h2>How can I control cookies?</h2>

                        <p>
                            You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
                        </p>

                        <p>
                            The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.
                        </p>

                        <p>
                            The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):
                        </p>

                        <h2>Essential website cookies:</h2>

                        <p>
                            These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.
                        </p>


                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cookie</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Purpose</TableCell>
                                        <TableCell>Duration</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>CAPTCHA</TableCell>
                                        <TableCell>CAPTCHA Cookies</TableCell>
                                        <TableCell>Used to distinguish human users from bots specifically for form submissions. This is necessary for the security of the form submission process on the website and the protection against spam and abuse.</TableCell>
                                        <TableCell>Session</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Google Maps</TableCell>
                                        <TableCell>Google Maps Cookies</TableCell>
                                        <TableCell>Used by Google to collect information about how visitors use the maps feature. This includes details like the visitor's behavior on the map and whether they interact with the map. These cookies are set by Google when the map is embedded on our site.</TableCell>
                                        <TableCell>Varies</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <h2>Analytics and customization cookies:</h2>

                        <p>
                            These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
                        </p>

                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Cookie</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Purpose</TableCell>
                                        <TableCell>Duration</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>_ga</TableCell>
                                        <TableCell>Google Analytics</TableCell>
                                        <TableCell>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</TableCell>
                                        <TableCell>6 months</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>_gid</TableCell>
                                        <TableCell>Google Analytics</TableCell>
                                        <TableCell>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</TableCell>
                                        <TableCell>24 hours</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>_gat</TableCell>
                                        <TableCell>Google Analytics</TableCell>
                                        <TableCell>Used by Google Analytics to throttle request rate</TableCell>
                                        <TableCell>1 minute</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>CLID</TableCell>
                                        <TableCell>Microsoft Clarity</TableCell>
                                        <TableCell>Stores a unique ID for the session to help understand how the user interacts with the website.</TableCell>
                                        <TableCell>6 months</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>ANONCHK</TableCell>
                                        <TableCell>Microsoft Clarity</TableCell>
                                        <TableCell>Indicates whether it is allowed to collect data on user's navigation and interactions for reporting purposes.</TableCell>
                                        <TableCell>10 minutes</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <Link to="/ee" > <img src="/img/assets/c.png" alt="EE" style={{ width: '20px', height: '20px' }} /> </Link>

                    </div>
                </div>
            </div>

        </>
    );
}
