/////////////////////////////////////////////
//Dependencies
import React from "react";
import { NavLink } from "react-router-dom";

/////////////////////////////////////////////

/**
 * DomainAdd component
 * 
 * @param {Object} props        - The component props.
 * @param {string} props.link   - The link for redirection.
 * @param {string} props.image  - The name of the image with extension.
 * @param {string} props.name   - The name of the category.
 * @returns {JSX.Element}       - The rendered DomainAdd component.
 */
export default function DomainAdd({ link,image ,name }) {
  return (
    <div className="card">
      <NavLink to={`categories/${link}`}>
        <img src={`img/machines/${image}`} alt={image}/>
        <div className="card_head">{name}</div>
      </NavLink>
    </div>
  );
};
