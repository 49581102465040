/////////////////////////////////////////////
//Dependencies
import React from 'react'

/////////////////////////////////////////////
//CSS
import "./BannerDomain.css"

/////////////////////////////////////////////
//Component

/**
 * BannerDomain component
 * 
 * @param {Object} props            - The component props.
 * @param {string} props.logo       - The logo image name.
 * @param {string} props.text       - The text to display.
 * @param {string} props.imagebg    - The background image name.
 * @returns {JSX.Element}           - The rendered BannerDomain component.
 */
export default function BannerDomain({logo, text, imagebg}) {
    return (
        <>
            <div className="bd-bg-top" style={{backgroundImage: `url(img/assets/${imagebg}`}}>
                <div className="bd-bg-top-top">
                    <img className="bd-bg-top-img" src={`./img/logo/${logo}.png`} alt={logo} />
                </div>
                <div className="bd-bg-top-bottom">
                    <h1 className='bd-bg-top-text'>{text}</h1>
                </div>
            </div>
        </>
    )
};
