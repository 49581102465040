/////////////////////////////////////////////
//Dependencies
import React from "react";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

/////////////////////////////////////////////
//CSS
import "./css/Company.css";

/////////////////////////////////////////////
//Components
import ParseText from "../Modules/parseText/ParseText"
import ReverseParseText from "../Modules/reverseParseText/ReverseParseText"

//////////////////////////////////////////////////////////////////////////////////////////

export default function Presentation() {
  const { t } = useTranslation();
  const pageTitle = "Plassys ▪ " + t("navbar.Company");
  const pageDescription = ReverseParseText(t("company.text") + " " + t("company.text2"));


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://plassys.com/presentation" />
      </Helmet>
      
      <div className="comp-container-page">

        <div className="comp-bg-top">
        </div>

        <div className="comp-valeurs">
          <div className="comp-card">
            <div className="comp-card-content">
              <h1 className="comp-card-title">{t("company.point.value.title")}</h1>
              <p className="comp-card-text">
                {t("company.point.value.text")}
              </p>
              <div className="spacer"></div>
            </div>
          </div>

          <div className="comp-card">
            <div className="comp-card-content">
              <h1 className="comp-card-title">{t("company.point.mission.title")}</h1>
              <p className="comp-card-text">
                {t("company.point.mission.text")}
              </p>
              <div className="spacer"></div>
            </div>
          </div>

          <div className="comp-card">
            <div className="comp-card-content">
              <h1 className="comp-card-title">{t("company.point.vision.title")}</h1>
              <p className="comp-card-text">
                {t("company.point.vision.text")}
              </p>
              <div className="spacer"></div>
            </div>
          </div>

        </div>


        <div className="comp-content-container">

          <div className="comp1">
            <div className="comp-content">
              <div className="comp-content-img">
                <img src="img/assets/bureaux.jpg" alt="Description" />
              </div>
              <div className="comp-content-text">
                <ParseText classN={"comp-content-anim"} text={t("company.text")} />
                <ParseText classN={"comp-content-anim"} text={t("company.text2")} />

                <div className="comp-content-button-container">
                  <NavLink to="/categories" className="comp-discover-link">
                    <button className="comp-discover-button">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                      </svg>
                      <div className="comp-discover-button-text">
                        {t("company.discover")}
                      </div>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="comp2">
          <div className="comp-content">
            <div className="comp-content-text">
                <ParseText text={t("company.text2")} />
            </div>
            <div className="comp-content-img">
              <img src="img/machines/machine4.png" alt="Description" /> //n'existe plus
            </div>
          </div>
        </div> */}

        </div>
      </div>
    </>
  );
}