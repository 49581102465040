/////////////////////////////////////////////
//Dependencies
import React from "react";

/////////////////////////////////////////////
//Component

/**
 * PartnersAdd component
 * 
 * @param {Object} props        - The component props.
 * @param {string} props.brand  - The brand name to display.
 * @returns {JSX.Element}       - The rendered PartnersAdd component.
 */
export default function PartnersAdd({ brand }) {
  return (
    <div className="marque-img-container">
      <img className="marque-img" src={`../img/marques/${brand}.png`} alt={brand} />
    </div>
  );
};
